import { cpf, cnpj } from "cpf-cnpj-validator";
import Payment from "payment";
import { isExpired } from "utils/validator";
import * as yup from "yup";

const CVV_REGEX = /^\d{3,4}$/;

export const SchemaCreditCard = yup.object().shape({
  number: yup
    .string()
    .required("Campo obrigatório")
    .test("validateNumber", "Número do cartão inválido.", (val) => {
      if (val) {
        if (Payment.fns.validateCardNumber(val)) {
          return true;
        } else {
          return false;
        }
      }
    }),
  due_date: yup
    .string()
    .required("Campo obrigatório")
    .test("validateDueDate", "Data inválida.", (val: any) => {
      if (val) {
        val = val.split("/");
        if (
          parseInt(val[0]) > 0 &&
          parseInt(val[0]) < 13 &&
          isExpired(parseInt(val[0]), parseInt(val[1]))
        ) {
          return true;
        } else {
          return false;
        }
      }
    }),
  cvv: yup
    .string()
    .required("Campo obrigatório")
    .matches(CVV_REGEX, "Código inválido"),
  name: yup
    .string()
    .required("Campo obrigatório")
    .test("validateName", "Nome inválido.", (val: any) => {
      if (val) {
        val = val.split(" ");
        if (val.length > 1) {
          return true;
        } else {
          return false;
        }
      }
    }),
  cpfCnpj: yup
    .string()
    .required("Campo obrigatório")
    .test("validateCpfCnpj", "Documento inválido.", (val: any) => {
      if (val) {
        if (val.length < 15 && cpf.isValid(val)) {
          return true;
        } else if (val.length > 14 && cnpj.isValid(val)) {
          return true;
        } else {
          return false;
        }
      }
    }),
  id_charge_option: yup.string().uuid(),
});
